import { postMapper } from '@wix/communities-blog-wix-code-common';
import { PLATFORM_API_URL } from '../constants/api';

const getPostBySlug = ({ instance, slug }) =>
  fetch(`${PLATFORM_API_URL}/posts/slugs/${slug}`, {
    headers: {
      Accept: 'application/json',
      Authorization: instance,
    },
  })
    .then(resp => (resp.ok ? resp.json() : {}))
    .then(json => json.post)
    .then(postMapper.mapRest)
    .catch(() => undefined);

const getPostById = ({ instance, id }) =>
  fetch(`${PLATFORM_API_URL}/posts/${id}`, {
    headers: {
      Accept: 'application/json',
      Authorization: instance,
    },
  })
    .then(resp => (resp.ok ? resp.json() : {}))
    .then(json => json.post)
    .then(postMapper.mapRest)
    .catch(() => undefined);

const getLastPost = ({ instance }) =>
  fetch(`${PLATFORM_API_URL}/posts?limit=1`, {
    headers: {
      Accept: 'application/json',
      Authorization: instance,
    },
  })
    .then(resp => (resp.ok ? resp.json() : {}))
    .then(json => (json.post ? json.post[0] : {}))
    .then(postMapper.mapRest)
    .catch(() => undefined);

export default {
  getPostById,
  getPostBySlug,
  getLastPost,
};
