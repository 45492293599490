import emptyController from './controllers/empty';
import postPageController from './controllers/post-page';
import { POST_WIDGET_ID_DEV, POST_WIDGET_ID_PROD } from './constants/widgets';

const controllerByType = {
  [POST_WIDGET_ID_PROD]: postPageController,
  [POST_WIDGET_ID_DEV]: postPageController,
};

export const initAppForPage = () => Promise.resolve();

export const createControllers = controllerConfigs =>
  controllerConfigs.map(config => Promise.resolve((controllerByType[config.type] || emptyController)(config)));

export const exports = {};
